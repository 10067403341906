@font-face {
    font-family: "Coalition";
    src: url("../public/fonts/Coalition.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Industry";
    src: url("../public/fonts/Industry.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

html,
body,
#__next {
    height: calc((var(--vh, 1vh) * 99.9));
}

html {
    font-size: 16px;
}

a {
    color: inherit;
    text-decoration: none;
}

body {
    background: #080912;
}

.fade {
    position: absolute;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #040914 0%, rgba(4, 9, 20, 0) 100%);
}

.fadeRevert {
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: -1px;
    left: 0;
    background: linear-gradient(180deg, #040914 0%, rgba(4, 9, 20, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    margin: 0 auto;
}

.grid {
    text-align: center;
    column-gap: 1rem;
    row-gap: 1rem;
    display: grid;
    justify-content: center;
    justify-items: center;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    width: 1280px;
}

@media (min-width: 1900px) {
    html {
        font-size: 24px
    }
}

@media only screen and (max-width: 1366px) {
    .container {
        padding: 0 1rem;
    }
}

@media only screen and (max-width: 1280px) {
    .grid {
        width: 100%;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media only screen and (max-width: 1024px) {
    .grid {
        width: 100%;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media only screen and (max-width: 768px) {
    .grid {
        width: 100%;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.main-progress-bar {
    height: 2px;
    background: #696679;
}

.main-progress-bar-active {
    height: 2px;
}

@keyframes w-fill {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

.fill-action::after {
    content: "";
    animation-name: w-fill;
    animation-duration: inherit;
    animation-iteration-count: inherit;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    background-color: #D7FC51;
    width: 0;

    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
}

.letter_space {
    letter-spacing: 0.16em;
}

.swiper-wrapper {
    display: flex;
    align-items: center !important;
}

@media (min-width: 460px) {
    #nftSwiper .swiper-slide-active {
        width: 40% !important;
    }

    #nftSwiper .swiper-slide-next {
        width: 28% !important;
    }

    #nftSwiper .swiper-slide-prev {
        width: 28% !important;
    }
}

@media (min-width: 760px) {
    #nftSwiper .swiper-slide-active {
        width: 38% !important;
    }

    #nftSwiper .swiper-slide-next {
        width: 30% !important;
    }

    #nftSwiper .swiper-slide-prev {
        width: 30% !important;
    }
}

@media (min-width: 1280px) {
    #nftSwiper .swiper-slide {
        width: 19% !important;
    }

    #nftSwiper .swiper-slide-active {
        width: 22% !important;
    }
}

@media (min-width: 1920px) {
    #nftSwiper .swiper-slide {
        width: 19.3% !important;
    }

    #nftSwiper .swiper-slide-active {
        width: 22% !important;
    }
}
